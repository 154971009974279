import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriHybsova from "../../images/treneri/libuse-hybsova.jpg"

const LibuseHybsova = () => (

	<div>

		<Layout>
			<Seo title="Libuše Hybšová" description="Libuše Hybšová" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriHybsova} alt="Libuše Hybšová" />
							</div>
							<div>
								<h1 className="stk-h1">Libuše Hybšová</h1>
								<p>Trenérka - latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/libuse.chytkova" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--instagram" href="https://www.instagram.com/libusehybsova/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420724949323" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:chytkova.libuse@gmail.com" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Porotkyně a&nbsp;trenérka II.&nbsp;třídy</li>
							<li>Absolventka Fakulty tělesné výchovy a&nbsp;sportu (Mgr.&nbsp;titul)</li>
							<li>Držitelka mezinárodní taneční třídy&nbsp;"M" v&nbsp;latinskoamerických i&nbsp;standardních tancích</li>
							<li>Vítěz taneční ligy</li>
							<li>Mistryně ČR v&nbsp;Showdance v&nbsp;latinskoamerických tancích</li>
							<li>Vícemistryně MČR v&nbsp;latinskoamerických tancích a&nbsp;nekolikanásobná finalistka MČR v&nbsp;dalších letech</li>
							<li>Dvojnásobná mistryně MČR družstev v&nbsp;latinskoamerických tancích</li>
							<li>Několikanásobná finalistka mezinárodních soutěží World Open</li>
							<li>Čtvrtfinalistka MS a&nbsp;ME v&nbsp;latinskoamerických tancích</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default LibuseHybsova